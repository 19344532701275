<template>
  <section id="cmtpl-insurance-form-section">
    <div class="tab-content">
      <div class="tab-pane fade show active d-flex cmplt-tab-pane justify-content-between" id="isb-frame-custom">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-5  position-relative">
          <div class="row mt-1">
            <div class="
                    col-lg-12
                    tab-content
                    pb-5
                    pt-0
                    text-justify
                  ">
              <div id="cmtpl-insurance-form" class="cmtpl-insurance-form insurance-form tab-pane"
                v-bind:class="{ active: formState == 0 }">
                <request-form v-on:success="requestFormOnSubmitHandler" :loading="isLoading" />
              </div>

              <div id="insurance-company" class="tab-pane" v-bind:class="{ active: formState == 1 }">
                <ins-companies :premium="premium" :data="insCompanies" :loading="isLoading"
                  v-on:company-selected="companySelectedHandler" v-on:back="formState = 0" />
              </div>

              <div id="check-info" class="tab-pane" v-bind:class="{ active: formState == 2 }">
                <check-info :data="form" :premium="premium" v-on:confirm="issueContractHandler" :loading="isLoading"
                  v-on:back="formState = 1" />
              </div>

              <div id="payment" class="tab-pane" v-bind:class="{ active: formState == 3 }">
                <payment :loading="isLoading" :data="contractData" :contractNumber="contractData.contractNumber" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-5 ps-4  position-relative">
          <div class="cmtpl-insurance-icons-container ">
            <ul class="nav mx-auto pe-0 position-relative">
              <li class="position-relative cmplt-nav-items">
                <a class="
                            insurance-form-icon
                            cursor-pointer
                            nav-link
                            mx-auto
                            text-center
                            position-relative
                          " data-bs-toggle="pill" href="#cmtpl-insurance-form"
                  v-bind:class="{ active: formState == 0 }">
                  <div class="d-flex align-items-center">
                    <div class="ins-icon">
                      <img v-if="formState == 0" src="@/assets/images/ins_step1.svg" />
                      <img v-else src="@/assets/images/step_confirm.svg" />
                    </div>
                    <div class="ms-3 cmplt-nav-item-text">Əsas məlumatlar</div>
                  </div>
                  <div class="ins-icon-hr">
                    <img src="@/assets/images/ins_icons_hr.svg" />
                  </div>
                </a>
                <a class="
                            insurance-form-icon
                            cursor-pointer
                            nav-link
                            mx-auto
                            text-center
                            position-relative
                          " data-bs-toggle="pill" href="#insurance-company" v-bind:class="{
                            active: formState == 1,
                            disabled: formState < 1,
                          }">
                  <div class="d-flex align-items-center">
                    <div class="ins-icon">
                      <img v-if="formState == 0" src="@/assets/images/ins_step2.svg" />
                      <img v-else-if="formState == 1" src="@/assets/images/ins_step2_success.svg" />
                      <img v-else src="@/assets/images/step_confirm.svg" />
                    </div>
                    <div v-if="formState == 0" class="ms-3 cmplt-nav-item-text-active">Sığorta şirkətini seç</div>
                    <div v-else class="ms-3 cmplt-nav-item-text">Sığorta şirkətini seç</div>
                  </div>
                  <div class="ins-icon-hr">
                    <img src="@/assets/images/ins_icons_hr.svg" />
                  </div>
                </a>
                <a class="
                            insurance-form-icon
                            cursor-pointer
                            nav-link
                            mx-auto
                            text-center
                            position-relative
                          " data-bs-toggle="pill" href="#check-info" v-bind:class="{
                            active: formState == 2,
                            disabled: formState < 2,
                          }">
                  <div class="d-flex align-items-center">
                    <div class="ins-icon">
                      <img v-if="formState < 2" src="@/assets/images/ins_step3.svg" />
                      <img v-else-if="formState == 2" src="@/assets/images/ins_step3_success.svg" />
                      <img v-else src="@/assets/images/step_confirm.svg" />
                    </div>
                    <div v-if="formState < 2" class="ms-3 cmplt-nav-item-text-active">Məlumatı yoxla</div>
                    <div v-else class="ms-3 cmplt-nav-item-text">Məlumatı yoxla</div>
                  </div>
                  <div class="ins-icon-hr">
                    <img src="@/assets/images/ins_icons_hr.svg" />
                  </div>
                </a>
                <a class="
                            insurance-form-icon
                            cursor-pointer
                            nav-link
                            mx-auto
                            text-center
                            position-relative
                          " href="#payment" data-bs-toggle="pill" v-bind:class="{
                            active: formState == 3,
                            disabled: formState < 3,
                          }">
                  <div class="d-flex align-items-center">
                    <div class="ins-icon">
                      <img v-if="formState < 3" src="@/assets/images/ins_step4.svg" />
                      <img v-else-if="formState == 3" src="@/assets/images/ins_step4_success.svg" />
                      <img v-else src="@/assets/images/step_confirm.svg" />
                    </div>
                    <div v-if="formState < 3" class="ms-3 cmplt-nav-item-text-active">Ödəniş et</div>
                    <div v-else class="ms-3 cmplt-nav-item-text">Ödəniş et</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <error-modal ref="errorModal" />
</template>
  
<script>
import RequestForm from "@/views/cmtpl/RequestForm.vue";
import InsCompanies from "@/views/cmtpl/InsCompanies.vue";
import IsbFrame from "@/views/cmtpl/IsbFrame.vue";
import Payment from "@/views/cmtpl/Payment.vue";
import CheckInfo from "@/views/cmtpl/CheckInfo.vue";
import ErrorModal from '@/views/cmtpl/ErrorModal.vue';
import api from "@/api/cmtpl.api";

export default {
  components: { RequestForm, InsCompanies, IsbFrame, Payment, CheckInfo, ErrorModal },
  data: () => ({
    isLoading: false,
    formState: 0,
    form: {},
    requestData: {
      carNumber: null,
      certificateNumber: null,
      pin: null,
      idSerial: null,
      phoneNumber: null,
      hasLicenseId: false,
      licenseNumber: ""
    },
    initialRequestId: null,
    premium: 0,
    insCompanies: [],
    contractData: {},
    frameUrl: null,
    frameUrlOther: null,
  }),
  methods: {
    requestFormOnSubmitHandler(form) {
      this.isLoading = true;

      this.form = form;

      api.requestContract(form).then((data) => {
        this.initialRequestId = data;
        this.getAvailableCompanies(this.initialRequestId, 0);
      }).catch((error) => {
        this.$refs["errorModal"].show();
        this.isLoading = false;
      });
    },

    getAvailableCompanies(requestId, tryCount) {
      if (tryCount > 20) {
        this.isLoading = false;
        return;
      }

      api.getAvailableCompanies({ requestId }).then((data) => {
        if (data) {
          this.isLoading = false;

          this.premium = data.premium;
          this.insCompanies = data.companies;
          this.formState = this.formState + 1;
        } else {
          tryCount++;
          setTimeout(() => { this.getAvailableCompanies(requestId, tryCount) }, 3000);
        }
      }).catch((error) => {
        this.$refs["errorModal"].show();
        this.isLoading = false;
      });
    },

    companySelectedHandler(companyUid, companyName) {
      this.form.companyName = companyName;
      this.form.companyUid = companyUid;

      this.formState = 2;
    },

    issueContractHandler() {
      this.isLoading = true;

      let payload = { requestId: this.initialRequestId, companyUid: this.form.companyUid };

      api
        .issueContract(payload)
        .then((data) => {
          this.checkContract(data, 0);


          api.getActiveContracts()
            .then((data) => {
              let existedElement = document.querySelector(".ins-count-cmtpl");
              if (existedElement != null) {
                existedElement.textContent = data.length;
              } else {
                let item = document.querySelector(".nav-cmtpl-item");
                let count = document.createElement("span");
                count.classList.add("ins-count", "ins-count-cmtpl");
                count.textContent = data.length;
                item.appendChild(count);
              }

            })
            .catch((error) => this.emitter.emit("error", error))
        })
        .catch((error) => {
          this.emitter.emit("error", error);
          this.isLoading = false;
        });
    },

    checkContract(requestId, tryCount) {
      if (tryCount > 20) {
        this.isLoading = false;
        return;
      }

      api.getContractByRequest(requestId)
        .then((data) => {
          if (data) {
            this.isLoading = false;

            this.formState = 3;
            this.contractData = data;
          } else {
            tryCount++;
            setTimeout(() => { this.checkContract(requestId, tryCount); }, 3000);
          }
        })
        .catch((error) => {
          this.emitter.emit("error", error);
          this.isLoading = false;
        });
    },

    loadOtherFrame() {
      this.isLoading = true;

      api
        .getFrame(null)
        .then((data) => {
          this.frameUrlOther = data.url;
        })
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
  